<template>
    <div>
        <Header>
            <template v-slot:header-title>
                The Power of Partnership
            </template>
            <template v-slot:header-strapline>
                Our application has been designed from the ground up to empower our partners as they transform their
                businesses both technically and commercially to support their customers on their cloud journey.
            </template>
        </Header>
        <section class="partner-grid-section">
            <div class="partner-grid-controls"></div>
            <div class="partner-grid-filter-controls">
                <select name=""
                        id="countries"
                        class="countries-dropdown"
                        v-on:change="GetSelectedCountry($event)">
                    <option value=""
                            selected
                            disabled>Location:</option>
                    <!-- <option v-for="country in countries" :value="country.value">{{country.text}}</option> -->
                    <option value="">All</option>
                    <option value="apac">APAC</option>
                    <option value="eu">Europe</option>
                    <option value="uk">UK</option>
                    <option value="us">USA</option>
                </select>
                <!-- Filter by Vendor, Distributor, Reseller, Service Provider -->
                <div class="filter-control partner-type"
                     @click="FilterPartners('vendor')"
                     :class="{ active: vendorActive }">
                    Vendor
                </div>
                <div class="filter-control partner-type"
                     @click="FilterPartners('distributor')"
                     :class="{ active: distributorActive }">
                    Distributor
                </div>
                <div class="filter-control partner-type"
                     @click="FilterPartners('reseller')"
                     :class="{ active: resellerActive }">
                    Reseller
                </div>
                <div class="filter-control partner-type"
                     @click="FilterPartners('service-provider')"
                     :class="{ active: serviceProviderActive }">
                    Service Provider
                </div>
                <!-- List Partners -->
                <div class="filter-control layout"
                     @click="ChangePartnerLayout('list')"
                     :class="{ active: listActive }">
                    <i class="bi bi-list-ul"></i>
                </div>
                <!-- Grid Partners -->
                <div class="filter-control layout"
                     @click="ChangePartnerLayout('grid')"
                     :class="{ active: gridActive }">
                    <i class="bi bi-grid-fill"></i>
                </div>
                <!-- Clear Filters -->
                <div class="filter-control"
                     @click="FilterPartners('clear-filters')">Clear All Filters</div>
            </div>
            <PartnerGrid></PartnerGrid>
        </section>
    </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import PartnerGrid from "../components/partners/PartnerGrid.vue";

export default {
    components: {
        Header,
        PartnerGrid
    },
    metaInfo() {
        return {
            title: 'Partners | Illapa Cloud',
            meta: [
                {
                    name: 'description',
                    content: 'Our application has been designed from the ground up to empower our partners as they transform their businesses both technically and commercially to support their customers on their cloud journey.'
                },
                {
                    property: 'og:title',
                    content: 'Partners | Illapa Cloud'
                },
                {
                    property: 'og:description',
                    content: 'Our application has been designed from the ground up to empower our partners as they transform their businesses both technically and commercially to support their customers on their cloud journey.'
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud/partners'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud/partners'
                }
            ]
        }
    },
    created() {
        window.addEventListener("resize", this.onResize);
        // if (this.windowWidth < 600 || this.windowWidth >= 900) {
        //     this.gridActive = true;
        // }
        // if (this.windowWidth >= 600 && this.windowWidth < 900) {
        //     this.listActive = true;
        // }
        this.ChangePartnerLayout('list');
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    watch: {
        windowWidth: function () {
            if (this.windowWidth < 600 || this.windowWidth >= 900) {
                if (!this.listActive) {
                    this.ChangePartnerLayout('grid');
                }
            }
            if (this.windowWidth >= 600 && this.windowWidth < 900) {
                this.ChangePartnerLayout('list');
            }
        },
    },
    data: () => ({
        // Grid is set to true by default
        listActive: true, gridActive: false,
        // Partner type filter states
        vendorActive: false, distributorActive: false, resellerActive: false, serviceProviderActive: false,
        windowWidth: window.innerWidth, windowHeight: window.innerHeight
    }),
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        ChangePartnerLayout(layout) {
            switch (layout) {
                case "list":
                    this.$root.$emit('change-layout', 'list');
                    this.listActive = true;
                    this.gridActive = false;
                    break;
                case "grid":
                    this.$root.$emit('change-layout', 'grid');
                    this.gridActive = true;
                    this.listActive = false;
                    break;
                default:
                    break;
            }
        },
        FilterPartners(type) {
            switch (type) {
                case "vendor":
                    this.FilterVendorPartners();
                    break;
                case "distributor":
                    this.FilterDistributorPartners();
                    break;
                case "reseller":
                    this.FilterResellerPartners();
                    break;
                case "service-provider":
                    this.FilterServiceProviderPartners();
                    break;
                case "clear-filters":
                    this.ClearAllPartnerTypeFilters();
                    document.getElementById('countries').selectedIndex = 0;
                    this.$root.$emit('filter-partner', 'clear-all');
                    break;
                default:
                    break;
            }
        },
        FilterVendorPartners() {
            this.$root.$emit('filter-partner', 'vendor');
            this.vendorActive = true;
            this.distributorActive = false;
            this.resellerActive = false;
            this.serviceProviderActive = false;
        },
        FilterDistributorPartners() {
            this.$root.$emit('filter-partner', 'distributor');
            this.distributorActive = true;
            this.vendorActive = false;
            this.resellerActive = false;
            this.serviceProviderActive = false;
        },
        FilterResellerPartners() {
            this.$root.$emit('filter-partner', 'reseller');
            this.resellerActive = true;
            this.vendorActive = false;
            this.distributorActive = false;
            this.serviceProviderActive = false;
        },
        FilterServiceProviderPartners() {
            this.$root.$emit('filter-partner', 'service provider');
            this.serviceProviderActive = true;
            this.vendorActive = false;
            this.distributorActive = false;
            this.resellerActive = false;
        },
        ClearAllPartnerTypeFilters() {
            this.vendorActive = false;
            this.distributorActive = false;
            this.resellerActive = false;
            this.serviceProviderActive = false;
        },
        GetSelectedCountry(e) {
            let country = e.target.value;
            this.$root.$emit('filter-partner-country', country);
        }
    }
}
</script>

<style scoped>
.partner-grid-section {
    background-color: #f4f7f9;
    padding-bottom: 160px;
}

.partner-grid-section .partner-grid-controls {
    position: relative;
    background-color: #fff;
    height: 70px;
    margin-bottom: 5px;
}

.partner-grid-section .partner-grid-filter-controls {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px 0;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    overflow-x: auto;
}

.partner-grid-section .partner-grid-filter-controls .countries-dropdown {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 4px;
    /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15); */
    font-family: inherit;
    font-size: 18px;
    cursor: pointer;
}

.partner-grid-section .partner-grid-filter-controls .filter-control {
    position: relative;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.15s ease, color 0.15s ease;
    user-select: none;
    border-radius: 4px;
    white-space: nowrap;
}

.partner-grid-section .partner-grid-filter-controls .filter-control:hover {
    color: var(--brand-accent-blue);
}

.partner-grid-section .partner-grid-filter-controls .filter-control:not(:first-child) {
    margin-left: 10px;
}

.partner-grid-section .partner-grid-filter-controls .filter-control i {
    font-size: 22px;
}

.partner-grid-section .partner-grid-filter-controls .filter-control.active {
    color: var(--brand-accent-blue);
}

.partner-grid-section .partner-grid-filter-controls .filter-control.layout {
    display: none;
}

.partner-grid-section .partner-grid-filter-controls .filter-control::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background-color: var(--brand-accent-blue);
    border-radius: 50px;
    bottom: 8px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: width 0.2s ease;
}

.partner-grid-section .partner-grid-filter-controls .filter-control:hover::after {
    width: 40%;
}

.partner-grid-section .partner-grid-filter-controls .filter-control.active::after {
    width: 40%;
}

@media only screen and (min-width: 600px) {
    .partner-grid-section .partner-grid-filter-controls .filter-control.layout {
        display: block;
    }
}

@media only screen and (max-width: 940px) {
    .partner-grid-section .partner-grid-filter-controls {
        padding: 5px 20px;
    }
}

@media only screen and (min-width: 940px) {
    .partner-grid-section .partner-grid-filter-controls {
        justify-content: center;
    }
}
</style>